"use server";

import { timestampRecord, currentUserId } from "./server";
import { meiliServerKnows } from "~/lib/server";
import { db } from "~/database";
import { withAuthor } from "~/repos/know";
import { authorSim } from "~/repos/account";
import { KnowUpdate, NewKnow } from "~/types/models";
import * as repo from "~/repos/know";

export const create = async (val: NewKnow) => {
  const authorId = await currentUserId();
  if (!authorId) throw "user not logged in";

  const data = await db
    .insertInto("knows")
    .values({ ...val, authorId })
    .returningAll()
    .returning((eb) => [withAuthor(eb)])
    .executeTakeFirstOrThrow();
  const doc = timestampRecord(data);

  await meiliServerKnows.addDocuments([doc]);
  return data;
};

export const update = async (id: string, val: KnowUpdate) => {
  const data = await repo.update(id, val);
  if (!data) throw "know not found";

  const authorId = data.authorId;
  if (!authorId) throw "user not logged in";
  const author = await authorSim(authorId);

  const know = {
    ...timestampRecord(data),
    author,
  };

  await meiliServerKnows.updateDocuments([know]);
  return data;
};

// const implies = await knex<Imply>("implies")
//   .where("knowId", id)
//   .joinRaw(
//     "left join knows propositions on propositions.id = any(implies.proposition_ids)"
//   )
//   // https://github.com/knex/knex/issues/882#issuecomment-1351747332
//   .select([
//     "implies.*",
//     knex.raw("array_agg(to_json(propositions.*)) as propositions"),
//   ])
//   .orderBy("implies.createdAt", "desc")
//   .groupBy(["implies.id"]);
export const query = async (id: string) => {
  return await repo.find(id);
};
